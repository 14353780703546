import React from 'react';
import Layout from '../../containers/gatsby/layout';
import Credibility from '../../containers/sections/Credibility/Credibility';
import ServiceBlurb from '../../containers/services/ServiceBlurb/ServiceBlurb';
import ServiceFAQs from '../../containers/services/ServiceFAQs/ServiceFAQs';
import BookOnlineContainer from '../../containers/sections/BookOnline/BookOnlineContainer';
import ServicesGallery from '../../containers/services/ServicesGallery/ServicesGallery';
import SEO from '../../containers/gatsby/seo';
import ServiceHeroContainer from '../../containers/heroes/ServiceHero/ServiceHeroContainer';

const FurnitureRemovalsPage = (props) => (
  <Layout overlayHeader={true} location={props.location}>
    <SEO title="Same-Day Furniture Courier & Delivery" keywords={[`sofa van service london`, `sofa mover London`, `furniture removals London`, `furniture courier`, `quick furniture collection`, `move sofa today`, `man and van`]} />
    <ServiceHeroContainer
      jobName={'Furniture & General Items'}
      title={'Furniture Courier'}
      description={'Need to move a sofa? No problem, we can be there within 1 hour to pick up anything you want to move'}
      heroImg={require('../../assets/services-heroes/sofa.jpg')}
      reviewImage={require('../../assets/avatars/1.png')}
      reviewContent={'JJD is a class act. Due to a family emergency I had no-one to help me with my house move. I used JJD to deliver some furniture I had bought, then re-booked three more times after experiencing the quality of the service. Even when I underestimated the number of loads JJD were able to provide a van and driver at short notice. Their drivers/helpers are absolutely brilliant. Nothing was too much trouble. I highly recommend the service and will use them again in future. Many thanks.'}
      reviewName={'Carol Bell'}
    />
    <Credibility />
    <ServiceBlurb
      blurbTitle={'Moving furniture has never been this easy'}
      blurb={'Tired of how complicated it is to book a service with removal companies? So are we. Some of those systems are designed to haggle you into paying more. This ends now. This is JJD.'}
      blurb2={'Need furniture courier in London? Just book online and we will find you a fully insured, approved local driver in minutes.'}
      blurb3={'If you\'re looking for an affordable man-and-van professional in your area to move a sofa, fridge, mattress, or bed, you\'ve come to the right place. Booking with JJD is just like ordering an Uber, but for vans. Prices are about the same as an UberXL for most jobs.'}
    />
    <ServicesGallery
      gallerySubtitle={'Making furniture removals easier and more affordable'}
      photos={[
        require('../../assets/service-gallery/1.jpg'),
        require('../../assets/service-gallery/2.png'),
        require('../../assets/service-gallery/3.jpg'),
        require('../../assets/service-gallery/4.jpg'),
        require('../../assets/service-gallery/5.jpg'),
        require('../../assets/service-gallery/6.jpg'),
        require('../../assets/service-gallery/8.jpg'),
        require('../../assets/service-gallery/9.png'),
        require('../../assets/service-gallery/10.png'),
        require('../../assets/service-gallery/11.jpg'),
        require('../../assets/service-gallery/12.jpg'),
        require('../../assets/service-gallery/13.jpg'),
        require('../../assets/service-gallery/14.jpg'),
        require('../../assets/service-gallery/15.jpg'),
        require('../../assets/service-gallery/16.jpg'),
        require('../../assets/service-gallery/17.jpg'),
        require('../../assets/service-gallery/18.jpg'),
        require('../../assets/service-gallery/19.jpg'),
        require('../../assets/service-gallery/20.jpg'),
        require('../../assets/service-gallery/21.jpeg'),
        require('../../assets/service-gallery/22.jpeg'),
        require('../../assets/service-gallery/23.jpeg'),
      ]}
    />
    <ServiceFAQs
      faqTitle={'FAQs'}
      faqSubTitle={'Got a question about furniture moves?'}
      faqs={[
        {
          question: 'Can I get furniture picked up and delivered today?',
          answer: 'Yes, booking is available 24/7 and we can be at your pickup point within 1 hour. We\'ve done emergency moving services in London and surrounding areas time and time again when other companies have let you down.'
        },
        {
          question: 'How many things can I put in the van?',
          answer: 'All the space is yours. You get the whole van going from your pickup address to your arrival destination (and any additional stops), so whatever you can fit in the van, we can transport it. Just make sure you choose the right van size. We have Medium, Long, and Luton vans at the ready.'
        },
        {
          question: 'Do you only operate in London and Greater London?',
          answer: 'No, we\'ve done jobs in Birmingham, Manchester, Glasgow, and other major cities - we\'re rapidly expanding with the goal of covering the whole of the UK and Europe. Just give us a call if you think we\'re out of range - the price will be the same.'
        },
        {
          question: 'Can I schedule for a later date?',
          answer: 'Yes! Just set and an arrival date and time when you book. It\'s that simple.'
        },
        {
          question: 'Why is JJD cheaper than traditional companies?',
          answer: 'Our scheduling and pricing system is more efficient. We rely on market price data to find you a man and van professional within minutes of booking. Most removal companies take somewhere between 30% to 50% commission to provide this service, which is a crazy amount. We pay our drivers fairly, and charge you less at the same time.'
        }
      ]}
    />
    <BookOnlineContainer />
  </Layout>
);

export default FurnitureRemovalsPage;
